import { isArray } from "lodash";

export const convertValueToArray = (value: string | string[] = []) => {
  const data: string[] = isArray(value) ? value : [value];

  return data;
};

export const getQueryParams = (path = "") => {
  if (!path) return "";

  const firstQuestionMarkIndex = path.indexOf("?");
  return path
    .substring(firstQuestionMarkIndex + 1)
    .replaceAll("=", ".")
    .replaceAll("&", "__");
};
