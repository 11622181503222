import axios, { AxiosResponse, Method, ResponseType } from "axios";

const instance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL,
  timeout: 120000,
  validateStatus: (status: number) => status < 400,
});

instance.interceptors.request.use((config: any) => {
  return config;
});

const Api = async <T>({
  url,
  method = "POST",
  data,
  params,
  responseType,
  headers,
}: {
  url: string;
  method?: Method;
  data?: any;
  params?: any;
  responseType?: ResponseType;
  headers?: any;
}): Promise<T> => {
  return instance
    .request({
      method,
      url,
      data,
      params,
      responseType,
      headers,
    })
    .then((resp: AxiosResponse<any>) => {
      return resp.data;
    })
    .catch((err) => {
      throw err;
    });
};

export default Api;
