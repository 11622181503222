import classNames from "classnames";
import { useTranslation } from "next-i18next";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { FC, useState } from "react";
import Button from "~/components/Button";
import Icon from "~/components/Icon";
import Social from "~/components/Social";
import Text from "~/components/Text";
import Colors from "~/constants/colors";
import { Menus } from "~/constants/menus";
import Trans from "~/types/translationKeys";
import styles from "../Header.module.scss";

interface HeaderDrawerProps {
  open: boolean;
  handleClose: () => void;
}

const HeaderDrawer: FC<HeaderDrawerProps> = ({ open, handleClose }) => {
  const router = useRouter();
  const { utm_source, utm_campaign } = router.query;
  const [openSubMenu, setOpenSubMenu] = useState("");
  const { t } = useTranslation();

  const onClose = () => {
    handleClose();
    setOpenSubMenu("");
  };

  return (
    <div
      className={classNames(styles.mobile_header, "flex flex-col lg:hidden", {
        [styles.open]: open,
      })}
    >
      <div className="flex items-center p-4">
        <div
          className={classNames(styles.hamburger, {
            [styles.active]: open,
          })}
          onClick={onClose}
        >
          <div className={styles.line} />
          <div className={styles.line} />
          <div className={styles.line} />
        </div>
        <Link href={`/#`} className={styles.header_logo}>
          <Image src="/svgs/logo.svg" alt="CX Genie logo" fill />
        </Link>
        <Link
          className="ml-auto"
          type="outline"
          href={{
            pathname: process.env.NEXT_PUBLIC_DASHBOARD_URL,
            query: {
              utm_source,
              utm_campaign: utm_campaign || "template_onboarding",
            },
          }}
        >
          <Button type="outline">{t(Trans.sign_in)}</Button>
        </Link>
      </div>
      <div className="flex flex-col flex-1 gap-6 px-4 py-2">
        <nav className={styles.nav}>
          <ul className={classNames(styles.menu_list, "flex-wrap")}>
            {Menus.map((menu, index) => {
              const isActive = openSubMenu === menu.text;

              return (
                <div className={styles.menu_item} key={index}>
                  {menu.submenu ? (
                    <div
                      className={classNames(styles.label, {
                        [styles.active]: isActive,
                      })}
                      onClick={() => setOpenSubMenu(isActive ? "" : menu.text)}
                    >
                      {t(menu.text)}
                      <Icon
                        name={
                          isActive ? "arrow-square-up-2" : "arrow-square-down-2"
                        }
                        color={isActive ? Colors.PRIMARY : Colors.GREY_600}
                      />
                    </div>
                  ) : (
                    <Link
                      className={styles.label}
                      href={menu.link}
                      aria-label={menu.text}
                      onClick={onClose}
                    >
                      {t(menu.text)}
                    </Link>
                  )}
                  {menu.submenu && isActive ? (
                    <div className={styles.submenu_list}>
                      {menu.submenu.map((submenu, index) => (
                        <Link
                          key={index}
                          className={styles.submenu_item}
                          href={submenu.link}
                          aria-label={submenu.label}
                          onClick={onClose}
                          target={
                            submenu.link?.startsWith("https")
                              ? "_blank"
                              : undefined
                          }
                        >
                          {t(submenu.label)}
                        </Link>
                      ))}
                    </div>
                  ) : null}
                </div>
              );
            })}
          </ul>
        </nav>
        {/* <DropdownLanguage /> */}
        <div className="flex flex-col gap-2">
          <Text type="title-3" color={Colors.GREY_700}>
            {t(Trans.follow_us)}
          </Text>
          <Social color={Colors.PRIMARY} />
        </div>

        <div className="py-3 border-t-[#D6DAE1] border-t">
          <Text color={Colors.GREY_200}>
            {t(Trans["2024_cx_genie_all_rights_reserved"])}
          </Text>
        </div>
      </div>
    </div>
  );
};

export default HeaderDrawer;
