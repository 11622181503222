/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable @next/next/no-img-element */
import AOS from "aos";
import "aos/dist/aos.css";
import { appWithTranslation, useTranslation } from "next-i18next";
import type { AppProps } from "next/app";
import dynamic from "next/dynamic";
import Head from "next/head";
import { useRouter } from "next/router";
import Script from "next/script";
import NextNProgress from "nextjs-progressbar";
import { Fragment, useEffect } from "react";
import { useCookies } from "react-cookie";
import { hotjar } from "react-hotjar";
import "react-tooltip/dist/react-tooltip.css";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import Footer from "~/components/Footer";
import Header from "~/components/Header";
import "~/styles/globals.css";
import "~/styles/icons.scss";
import "~/styles/text.scss";
import Trans from "~/types/translationKeys";
import { getQueryParams } from "~/utils/common";

const CookieBanner = dynamic(() => import("~/components/CookieBanner"), {
  ssr: false,
});

const App = ({ Component, pageProps }: AppProps) => {
  const router = useRouter();
  const [_, setCookie] = useCookies(["utm_source", "others"]);
  // const changeLanguage = useChangeLanguage();
  const { t } = useTranslation();

  useEffect(() => {
    if (typeof window !== "undefined") {
      const utmSource = router.query.utm_source as string;
      if (utmSource) {
        setCookie("utm_source", utmSource);
      }
    }
  }, [router.query.utm_source]);

  useEffect(() => {
    if (typeof window !== "undefined" && getQueryParams(router.asPath)) {
      setCookie("others", getQueryParams(router.asPath));
    }
  }, [router.asPath]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      AOS.init({ duration: 750, easing: "ease-in-out-cubic", once: true });
    }

    if (
      typeof window !== "undefined" &&
      process.env.NODE_ENV === "production"
    ) {
      hotjar.initialize(
        Number(process.env.NEXT_PUBLIC_HOTJAR_ID || "0"),
        Number(process.env.NEXT_PUBLIC_HOTJAR_SV || "0")
      );
    }

    // const lang = localStorage.getItem(LocalKeys.LANGUAGE) || navigator.language;

    // // language allow only en and vi
    // const allowedLanguage = ["en", "vi"];

    // if (allowedLanguage.includes(lang)) {
    //   changeLanguage(lang === "en" ? "en" : "vi");
    // }
  }, []);

  return (
    <Fragment>
      <Head>
        <title key="title">
          {t(Trans.cx_genie_transform_business_with_ai_simple_but_significant)}
        </title>
        <meta
          key="mt:title"
          name="title"
          content={t(
            Trans.cx_genie_transform_business_with_ai_simple_but_significant
          )}
        />
        <meta
          key="description"
          name="description"
          content={t(
            Trans.cx_genie_is_your_virtual_agent_that_merges_ai_and_your_support_team_to_deliver_faster_more_consistent_and_tailored_customer_experience
          )}
        />

        <meta
          name="og:title"
          content={t(
            Trans.cx_genie_transform_business_with_ai_simple_but_significant
          )}
        />
        <meta
          name="og:description"
          content={t(
            Trans.cx_genie_is_your_virtual_agent_that_merges_ai_and_your_support_team_to_deliver_faster_more_consistent_and_tailored_customer_experience
          )}
        />
        <meta property="og:type" content="website" />
        <meta
          key="og:image"
          property="og:image"
          content="https://cxgenie.ai/sharing-image.png"
        />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta
          key="og:image:alt"
          property="og:image:alt"
          content={t(
            Trans.cx_genie_is_a_powerful_and_optimized_platform_for_enhancing_customer_engagement_with_minimal_effort
          )}
        />
        <meta
          key="twitter:title"
          name="twitter:title"
          content={t(
            Trans.cx_genie_transform_business_with_ai_simple_but_significant
          )}
        />
        <meta
          key="twitter:description"
          name="twitter:description"
          content={t(
            Trans.cx_genie_is_your_virtual_agent_that_merges_ai_and_your_support_team_to_deliver_faster_more_consistent_and_tailored_customer_experience
          )}
        />
        <meta property="twitter:card" content="summary_large_image" />
        <meta
          key="twitter:image"
          name="twitter:image"
          content="https://cxgenie.ai/sharing-image.png"
        />
      </Head>

      <NextNProgress color={"#364de7"} height={5} />

      <main id="root">
        <Header />
        <Component {...pageProps} />
        <Footer />
        <CookieBanner />
      </main>

      <Script async defer id="facebook-pixel" strategy="afterInteractive">
        {`
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '${process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID}');
          fbq('track', 'PageView');
        `}
      </Script>

      <noscript>
        <img
          height="1"
          width="1"
          style={{ display: "none" }}
          src={`https://www.facebook.com/tr?id=${process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID}&ev=PageView&noscript=1`}
        />
      </noscript>

      <Script
        async
        defer
        id="chat-widget"
        src={process.env.NEXT_PUBLIC_WIDGET_URL}
        data-bot_id={process.env.NEXT_PUBLIC_VA_ID}
        strategy="afterInteractive"
      />

      <Script
        async
        defer
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GA_MEASURE_ID_3}`}
        strategy="afterInteractive"
      />

      <Script async defer id="gtag-setup" strategy="afterInteractive">
        {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());          
  
            gtag('config', '${process.env.NEXT_PUBLIC_GA_MEASURE_ID_3}');
            gtag('config', 'AW-11378016077');
          `}
      </Script>

      <Script async defer id="demo-conversion" strategy="afterInteractive">
        {`
            function demo_conversion() {
              var callback = function () {
                console.log('')
              };
              gtag('event', 'conversion', {
                  'send_to': 'AW-11378016077/pIYgCOu0goEZEM3-urEq',
                  'event_callback': callback
              });
            }
          `}
      </Script>

      <Script
        async
        defer
        id="create-bot-conversion"
        strategy="afterInteractive"
      >
        {`
            function create_bot_conversion(url) {
              var callback = function () {
                console.log('')
              };
              gtag('event', 'conversion', {
                  'send_to': 'AW-11378016077/8U5YCOSahoEZEM3-urEq',
                  'event_callback': callback
              });
              return false;
            }
          `}
      </Script>
    </Fragment>
  );
};

export default appWithTranslation(App);
