import classNames from "classnames";
import { DetailedHTMLProps, FC, InputHTMLAttributes } from "react";
import styles from "./Input.module.scss";

interface InputProps
  extends DetailedHTMLProps<
    InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  label?: string;
  error?: string | false;
  labelClassName?: string;
  containerClassName?: string;
}

const Input: FC<InputProps> = ({
  label,
  className,
  error,
  labelClassName,
  containerClassName,
  ...rest
}) => {
  return (
    <div className={classNames(styles.input_container, containerClassName)}>
      {label && (
        <label className={classNames(styles.label, labelClassName)}>
          {label}
        </label>
      )}
      <input className={classNames(styles.input, className)} {...rest} />
      {error && <span className={styles.error}>{error}</span>}
    </div>
  );
};

export default Input;
