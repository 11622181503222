import Trans from "~/types/translationKeys";

export const Menus = [
  {
    text: Trans.discover,
    link: "/#key-features",
    key: "discover",
  },
  {
    text: Trans.features,
    link: "/#features",
    key: "features",
  },
  {
    text: Trans.pricing,
    link: "/#pricing",
    key: "pricing",
  },
  {
    text: Trans.ai_studio,
    link: "/ai_studio",
    key: "ai-studio",
  },
  {
    text: Trans.tools,
    submenu: [
      // {
      //   label: Trans.e_commerce_chatbot,
      //   link: `${process.env.NEXT_PUBLIC_DASHBOARD_URL}/sign-up?source=landing_page`,
      // },
    ],
    key: "tools",
  },
  {
    text: Trans.resources,
    link: "/resources",
    submenu: [
      {
        label: Trans.product_videos,
        link: "/resources/product-videos",
      },
      {
        label: Trans.blogs,
        link: "/resources/blogs",
      },
      {
        label: Trans.case_studies,
        link: "/resources/case-study",
      },
      {
        label: Trans.releases,
        link: "/resources/releases",
      },
    ],
    key: "resources",
  },
];
