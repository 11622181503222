import Api from "../api";
import { Template, TemplateStatus } from "./template.type";

const MODULE = "templates";
const API_VERSION = "v1";

interface GetTemplateListParams {
  limit?: number;
  page?: number;
  name?: string;
  category_id?: string;
  type_id?: string;
  status?: TemplateStatus;
}

const getList = async ({
  limit = 10,
  page = 1,
  ...rest
}: GetTemplateListParams) => {
  try {
    const offset = (page - 1) * limit;
    const res = await Api<ListResponse<Template>>({
      url: `/${API_VERSION}/${MODULE}`,
      method: "GET",
      params: {
        limit,
        offset,
        ...rest,
      },
    });

    return res.data;
  } catch (error) {
    throw error;
  }
};

const getDetail = async (id: string) => {
  try {
    const res = await Api<DetailResponse<Template>>({
      url: `/${API_VERSION}/${MODULE}/${id}`,
      method: "GET",
    });

    return res.data;
  } catch (error) {
    throw error;
  }
};

const create = async (payload: Partial<Template>) => {
  try {
    const res = await Api<DetailResponse<Template>>({
      url: `/${API_VERSION}/${MODULE}`,
      method: "POST",
      data: payload,
    });

    if (res.status === 200) {
      return res.data;
    }
  } catch (error) {
    throw error;
  }
};

const update = async ({
  id,

  ...payload
}: Partial<Template>) => {
  try {
    delete payload.category;
    delete payload.created_at;
    delete payload.updated_at;
    delete payload.deleted_at;
    delete payload.type;
    const res = await Api<DetailResponse<Template>>({
      url: `/${API_VERSION}/${MODULE}/${id}`,
      method: "PUT",
      data: payload,
    });

    if (res.status === 200) {
      return res.data;
    }
  } catch (error) {
    throw error;
  }
};

const remove = async (id: string) => {
  try {
    const res = await Api<MessageResponse>({
      url: `/${API_VERSION}/${MODULE}/${id}`,
      method: "DELETE",
    });

    if (res.status === 200) {
      return res.message;
    }
  } catch (error) {
    throw error;
  }
};

const TemplateApi = {
  getList,
  create,
  getDetail,
  update,
  remove,
};

export default TemplateApi;
