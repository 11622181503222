import classNames from "classnames";
import { ButtonHTMLAttributes, DetailedHTMLProps, FC, ReactNode } from "react";
import { BeatLoader } from "react-spinners";
import styles from "./Button.module.scss";
import { IconNames } from "../Icon/Icon.type";
import Icon from "../Icon";
import Colors from "~/constants/colors";

interface ButtonProps
  extends Omit<
    DetailedHTMLProps<
      ButtonHTMLAttributes<HTMLButtonElement>,
      HTMLButtonElement
    >,
    "type"
  > {
  type?: "solid" | "outline" | "borderless" | "icon";
  variant?: "primary" | "secondary" | "danger" | "white";
  htmlType?: "button" | "submit" | "reset";
  loading?: boolean;
  icon?: IconNames;
  tooltip?: string;
  shape?: "normal" | "square";
  size?: "large" | "normal";
}

const Button: FC<ButtonProps> = ({
  type = "solid",
  children,
  className,
  htmlType,
  loading,
  disabled,
  variant = "primary",
  icon,
  tooltip,
  shape = "normal",
  size = "normal",
  ...rest
}) => {
  return (
    <button
      // @ts-ignore
      className={classNames(
        styles.btn,
        styles[type],
        styles[variant],
        styles[shape],
        styles[size],
        className,
        {
          [`${styles.disabled}`]: loading || disabled,
        }
      )}
      disabled={loading || disabled}
      type={htmlType}
      {...rest}
    >
      {loading ? (
        <BeatLoader
          color={
            type === "outline" || type === "borderless"
              ? variant === "danger"
                ? Colors.ACCENT_RED
                : Colors.PRIMARY
              : "white"
          }
          size={8}
        />
      ) : (
        <div className="flex items-center gap-2">
          {icon && <Icon name={icon} size={20} />}
          {children}
        </div>
      )}
    </button>
  );
};

export default Button;
