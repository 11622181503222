export enum BotDataTypes {
  TEXT = "TEXT",
  DOCUMENT = "DOCUMENT",
  Q_AND_A = "Q_AND_A",
  URL = "URL",
  TABLE_DATA = "TABLE_DATA",
}

export interface BotData {
  id: string;
  content: string;
  content_value?: string;
  type?: BotDataTypes;
  bot_id: string;
  created_at?: string;
  updated_at?: string;
  deleted_at?: string;
  title?: string;
  mimetype?: string;
  filename?: string;
  size?: number;
  total_bot_knowledge?: number;
  total_bot_knowledge_imported?: number;
  total_bot_knowledge_have_overlap?: number;
}

export interface TemplateCategory {
  id: string;
  name: string;
}

export interface TemplateType {
  id: string;
  name: string;
}

export enum TemplateStatus {
  UNPUBLISH = "UNPUBLISH",
  PUBLISHED = "PUBLISHED",
}

export interface TemplateQuestion {
  id: string;
  content: string;
  type: BotDataTypes;
  required: boolean;
}

export interface TemplateStep {
  id: string;
  name: string;
  order: number;
  questions: TemplateQuestion[];
}

export interface Template {
  id: string;
  status: TemplateStatus;
  thumbnail: string;
  note: string;
  category_id: string;
  type_id: string;
  steps: TemplateStep[];
  default_faqs: Partial<BotData>[];
  // default_message_flows: DefaultMessageFlow[];
  category: TemplateCategory;
  type: TemplateType;
  name: string;
  created_at: string;
  updated_at: string;
  deleted_at: string;
  seo_title: Record<string, string>;
  seo_description: Record<string, string>;
}
