import Image from "next/image";
import Link from "next/link";
import React from "react";

interface SaasButtonProps {
  mode: "light" | "dark";
}

const SaasButton: React.FC<SaasButtonProps> = ({ mode }) => {
  return (
    <Link href="https://saaschatbots.com/cx-genie" target="_blank">
      <Image
        src={`/svgs/saaschatbots_logo_text_${mode}.svg`}
        alt="Saas Chatbots"
        width={166}
        height={40}
        quality={100}
      />
    </Link>
  );
};

export default SaasButton;
